import React, { useCallback, useState } from "react";
import Card from "antd/es/card";
import Input from "antd/es/input";
import Button from "antd/es/button";
import authStore from "../../store/auth.store";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import Path from "../../enum/Path";
import styled from "styled-components";
import notification from "antd/es/notification";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {}

const Login: React.FC<Props> = observer(() => {
  const { authorized, logging } = authStore;
  const [password, setPassword] = useState<string>("");

  const handleLogon = useCallback(() => {
    if (password.trim().length) {
      authStore.login(password);
    } else {
      notification.error({ message: "Пароль не может быть пустым" });
    }
  }, [password]);

  if (authorized) {
    return <Redirect to={Path.HOME} />;
  }

  return (
    <Container>
      <Card title="Авторизация" bordered={false} style={{ width: 300 }}>
        <p>Введите пароль</p>
        <Input
          placeholder="Пароль"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <br />
        <Button loading={logging} onClick={handleLogon}>
          Войти
        </Button>
      </Card>
    </Container>
  );
});

export default Login;
