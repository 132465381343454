import { action, makeObservable, observable } from "mobx";
import authService from "../service/network/auth.service";
import User from "../type/entity/User";
import departmentStore from "./department.store";
import userStore from "./user.store";
import UserRole from "../enum/UserRole";
import NetworkData from "../type/NetworkData";

export class AuthStore {
  storageTokenKey = "__TOKEN__";
  authChecking: boolean = false;
  logging: boolean = false;
  authorized: boolean = false;
  initialized: boolean = false;
  token?: string;
  currentUser?: User;

  setToken(token: string) {
    localStorage.setItem(this.storageTokenKey, token);
    this.token = token;
  }

  logout() {
    localStorage.removeItem(this.storageTokenKey);
    this.token = undefined;
    this.authorized = false;
    this.initialize();
  }

  async login(password: string) {
    this.logging = true;
    authService
      .get("login", { password })
      .then(({ data }) => {
        if (data.token) {
          this.setToken(data.token);
          this.initialize();
        }
      })
      .finally(() => {
        this.logging = false;
      });
  }

  initialize(): Promise<boolean> {
    this.authChecking = true;
    return authService
      .get()
      .then(async (response: NetworkData<User>) => {
        this.authorized = true;
        this.currentUser = response.data;
        await Promise.all([
          departmentStore.departmentListFetch(),
          userStore.userListFetch(),
        ]);
        return Promise.resolve(true);
      })
      .catch(() => {
        this.authorized = false;
        return Promise.resolve(false);
      })
      .finally(() => {
        this.authChecking = false;
        this.initialized = true;
      });
  }

  isOwner() {
    return this.currentUser?.roleId === UserRole.ADMIN;
  }

  constructor() {
    makeObservable(this, {
      authChecking: observable,
      authorized: observable,
      initialized: observable,
      currentUser: observable,
      logging: observable,
      logout: action.bound,
      initialize: action.bound,
      isOwner: action.bound,
      login: action.bound,
    });
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    if (token) {
      queryParams.delete("token");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${queryParams}${window.location.hash}`
      );
      this.setToken(token);
    }
    this.token = localStorage.getItem(this.storageTokenKey) ?? undefined;
  }
}

const authStore = new AuthStore();

export default authStore;
