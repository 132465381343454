import Dated, { RawDated } from "../type/generic/Dated";
import dayjs from "dayjs";

const defaultDataMapper = (row: RawDated): Dated<Date> => {
  return {
    ...row,
    createdAt: dayjs(row.createdAt).toDate(),
    updatedAt: dayjs(row.updatedAt).toDate(),
  };
};

export default defaultDataMapper;
