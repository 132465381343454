import React from "react";
import DepartmentUserSelect from "../../../component/layout/DepartmentUserSelect";
import taskStore from "../../../store/task.store";
import styled from "styled-components";
import Select from "antd/es/select";
import TaskStatus, {
  getAllTaskStatuses,
  getTaskStatusName,
} from "../../../enum/TaskStatus";
import { observer } from "mobx-react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {}

const Filter: React.FC<Props> = observer(() => {
  const { statusIds, executorId, departmentId } = taskStore.taskFilter;
  return (
    <Container>
      <div>
        <b>Исполнитель</b>
        <div>
          <DepartmentUserSelect
            allowClear
            departmentId={departmentId}
            userId={executorId}
            onChange={(departmentId, executorId) => {
              taskStore.updateTaskFilter({ departmentId, executorId });
            }}
            placeholder="Все подразделения"
          />
        </div>
      </div>
      <div>
        <b>Статус</b>
        <div>
          <Select
            options={getAllTaskStatuses().map((status) => ({
              value: status,
              label: getTaskStatusName(status as TaskStatus),
            }))}
            onChange={(statusIds) => {
              taskStore.updateTaskFilter({ statusIds });
            }}
            mode="multiple"
            style={{ width: "100%" }}
            value={statusIds}
          />
        </div>
      </div>
    </Container>
  );
});

export default Filter;
