import { action, makeObservable, observable } from "mobx";
import Department from "../type/entity/Department";
import departmentService from "../service/network/department.service";
import NetworkListData from "../type/NetworkListData";
import initializeNetworkListData from "../util/initializeNetworkListData";

export class DepartmentStore {
  departmentListData: NetworkListData<Department> =
    initializeNetworkListData<Department>();
  departmentListUpdater(networkData: NetworkListData<Department>) {
    this.departmentListData = networkData;
  }
  departmentListFetch() {
    departmentService.fetchNetworkListData<Department>(
      this.departmentListUpdater
    );
  }

  departmentSaving: boolean = false;
  async createDepartment(name: string) {
    this.departmentSaving = true;
    const response = await departmentService.post({ name });
    this.departmentSaving = false;
    return response;
  }
  async updateDepartment(id: Department["id"], name: string) {
    this.departmentSaving = true;
    const response = await departmentService.put({ name }, id);
    this.departmentSaving = false;
    return response;
  }

  constructor() {
    makeObservable(this, {
      departmentSaving: observable,
      departmentListData: observable,
      departmentListUpdater: action.bound,
      createDepartment: action.bound,
      updateDepartment: action.bound,
    });
  }
}

const departmentStore = new DepartmentStore();

export default departmentStore;
