import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SecureRoute from "../component/misc/SecureRoute";
import Path from "../enum/Path";
import { observer } from "mobx-react-lite";
import authStore from "../store/auth.store";
import Login from "./login";
import Home from "./home";
import Settings from "./settings";
import AdminRoute from "../component/misc/AdminRoute";
import Loader from "../component/misc/Loader";

const App = observer(() => {
  const { authChecking, authorized, initialized } = authStore;

  useEffect(() => {
    authStore.initialize();
  }, []);

  if (authChecking || !initialized) {
    return <Loader />;
  }
  return (
    <Switch>
      <Route path={Path.LOGIN} exact>
        <Login />
      </Route>
      <SecureRoute exact path={Path.HOME} isAuth={authorized}>
        <Home />
      </SecureRoute>
      <AdminRoute exact path={Path.SETTINGS}>
        <Settings />
      </AdminRoute>
    </Switch>
  );
});

export default App;
