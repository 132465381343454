import authStore from "../../store/auth.store";
import queryString from "query-string";
import notification from "antd/es/notification";
import NetworkListData from "../../type/NetworkListData";

class BaseService {
  type?: string;
  apiUrl: string = "";

  constructor(type: string) {
    this.type = type;
    this.apiUrl = process.env.REACT_APP_API_URL ?? "";
  }

  getHeaders(noContentType: boolean = false): Record<string, string> {
    const headers: Record<string, string> = { token: authStore.token ?? "" };
    if (!noContentType) {
      headers["Content-Type"] = "application/json";
    }
    return headers;
  }

  request(
    method: string,
    url?: string | number,
    payload?: any,
    body?: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${this.apiUrl}/${this.type}${url ? `/${url}` : ""}`, {
          method,
          headers: this.getHeaders(!!body),
          ...(payload || body ? { body: body ?? JSON.stringify(payload) } : {}),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              resolve({ data: res.data, total: res.total });
            } else {
              if (res.data.errorMessage === "Not Found") {
                notification.error({ message: "Неверный пароль" });
              } else if (res.data.errorMessage) {
                notification.error({ message: res.data.errorMessage });
              }
              reject("Something wrong");
            }
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  fetchNetworkListData<S>(
    updater: (data: NetworkListData<S>) => void,
    url?: string | number,
    params?: object,
    dataMapper?: (row: any) => any
  ) {
    updater({
      data: [],
      loading: true,
      errored: false,
    });
    this.get(url, params)
      .then(({ data, total }) => {
        updater({
          data: dataMapper ? data.map(dataMapper) : data,
          total,
          loading: false,
          errored: false,
        });
      })
      .catch(() => {
        updater({
          data: [],
          loading: false,
          errored: true,
        });
      });
  }

  get(url?: string | number, params?: object): Promise<any> {
    return this.request(
      "get",
      `${url ?? ""}${
        params
          ? "?" + queryString.stringify(params, { arrayFormat: "bracket" })
          : ""
      }`
    );
  }

  post(payload: any, url?: string | number, body?: any): Promise<any> {
    return this.request("POST", url, payload, body);
  }

  put(payload: any, url?: string | number): Promise<any> {
    return this.request("PUT", url, payload);
  }

  delete(url?: string | number): Promise<any> {
    return this.request("DELETE", url);
  }
}

export default BaseService;
