import React, { useMemo } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import User from "../../type/entity/User";
import colorList from "../../util/colorList";
import Row from "antd/es/row";
import Col from "antd/es/col";

const Container = styled.div<{ micro?: boolean; mini?: boolean }>`
  font-size: ${(props) =>
    props.micro ? "0.5rem" : props.mini ? "0.75rem" : "1rem"};
  height: ${(props) => (props.micro ? "1rem" : props.mini ? "1.5rem" : "2rem")};
  width: ${(props) => (props.micro ? "1rem" : props.mini ? "1.5rem" : "2rem")};
  border-radius: ${(props) =>
    props.micro ? "0.5rem" : props.mini ? "0.75rem" : "1rem"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  micro?: boolean;
  mini?: boolean;
  showName?: boolean;
  user: User;
}

const UserIcon: React.FC<Props> = observer(
  ({ micro, mini, showName, user }) => {
    const letters = useMemo(
      () =>
        user.name
          .split(" ")
          .slice(0, 2)
          .map((word) => word[0]),
      [user.name]
    );
    const { isDark, color } =
      colorList[(user.id + colorList.length) % colorList.length];

    return (
      <Row align="middle" gutter={4}>
        <Col>
          <Container
            mini={mini}
            micro={micro}
            style={{
              color: isDark ? "white" : "black",
              backgroundColor: color,
            }}
          >
            {letters}
          </Container>
        </Col>
        {showName && <Col>{user.name}</Col>}
      </Row>
    );
  }
);

export default UserIcon;
