import ColorShape from "../type/ColorShape";

const colorList: ColorShape[] = [
  { color: "#65D14F", isDark: false },
  { color: "#80A788", isDark: false },
  { color: "#043820", isDark: true },
  { color: "#85CEE6", isDark: false },
  { color: "#95FE84", isDark: false },
  { color: "#C70F45", isDark: true },
  { color: "#75510F", isDark: true },
  { color: "#2D2D6A", isDark: true },
  { color: "#ED4DCE", isDark: false },
  { color: "#ED4DCE", isDark: false },
  { color: "#091F39", isDark: true },
];

export default colorList;
