import React, { useCallback, useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Segmented } from "antd";
import { observer } from "mobx-react-lite";
import taskStore from "../../../store/task.store";
import Comments from "./Comments";
import Timeline from "./Timeline";
import General from "./General";
import styled from "styled-components";

const TabContainer = styled.div`
  padding-top: 16px;
`;

interface Props {}

const CreateEditTask: React.FC<Props> = observer(() => {
  const { task } = taskStore.taskModalState;
  const [tab, setTab] = useState<number>(1);
  const eventCount = taskStore.eventsListData.data.length;

  const handleHide = useCallback(() => {
    taskStore.setTaskModalState({ visible: false });
  }, []);

  useEffect(() => {
    if (task?.id) {
      taskStore.eventListFetch(task.id);
    }
  }, [task]);

  return (
    <Modal
      footer={null}
      open
      title={task ? "Редактирование задачи" : "Создать задачу"}
      onCancel={handleHide}
    >
      {task && (
        <>
          <Segmented
            onChange={(value) => setTab(value as number)}
            options={[
              { label: "Основное", value: 1 },
              { label: `Комментарии (${eventCount})`, value: 2 },
              { label: "Сводка", value: 3 },
            ]}
          />

          {tab === 2 && (
            <TabContainer>
              <Comments />
            </TabContainer>
          )}
          {tab === 3 && (
            <TabContainer>
              <Timeline />
            </TabContainer>
          )}
        </>
      )}
      <TabContainer>
        {(tab === 1 || !task) && <General onCLose={handleHide} />}
      </TabContainer>
    </Modal>
  );
});

export default CreateEditTask;
