import React, { useState } from "react";
import PageContainer from "../../component/layout/PageContainer";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import List from "antd/es/list";
import { observer } from "mobx-react-lite";
import departmentStore from "../../store/department.store";
import Department from "../../type/entity/Department";
import CreateEditDepartment from "../../component/misc/CreateEditDepartment";
import CreateEditUser from "../../component/misc/CreateEditUser";
import User from "../../type/entity/User";
import userStore from "../../store/user.store";
import Checkbox from "antd/es/checkbox";
import authStore from "../../store/auth.store";
import useChangeTracking from "../../util/hook/useChangeTracking";
import UserIcon from "../../component/UserIcon";

interface ModalShape<T> {
  entity?: T;
  visible: boolean;
}

const AddIcon = styled(PlusOutlined)`
  cursor: pointer;
`;

const EditIcon = styled(EditOutlined)`
  cursor: pointer;
`;
const MainRow = styled(Row)`
  width: 100%;
  padding: 10px;
`;

interface Props {}

const Settings: React.FC<Props> = observer(() => {
  const [informMeAboutNewTasks, setInformMeAboutNewTasks] = useState<boolean>(
    authStore.currentUser?.metadata?.informMeAboutNewTasks ?? false
  );
  const [informMeAboutTaskEvents, setInformMeAboutTaskEvents] =
    useState<boolean>(
      authStore.currentUser?.metadata?.informMeAboutTaskEvents ?? false
    );
  useChangeTracking(() => {
    const { id } = authStore.currentUser!;
    userStore.updateUserMetadata(
      id,
      JSON.stringify({ informMeAboutNewTasks, informMeAboutTaskEvents })
    );
  }, [informMeAboutNewTasks, informMeAboutTaskEvents]);

  const [depShape, setDepShape] = useState<ModalShape<Department>>({
    visible: false,
  });

  const [userShape, setUserShape] = useState<ModalShape<User>>({
    visible: false,
  });

  const departments = departmentStore.departmentListData.data;
  const departmentLoading = departmentStore.departmentListData.loading;

  const users = userStore.userListData.data;
  const userLoading = userStore.userListData.loading;

  return (
    <PageContainer title="Home page">
      <MainRow gutter={[12, 12]}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card
            title="Подразделения"
            bordered={false}
            extra={<AddIcon onClick={() => setDepShape({ visible: true })} />}
          >
            <List
              bordered
              loading={departmentLoading}
              dataSource={departments}
              renderItem={(department) => (
                <List.Item
                  actions={[
                    <EditIcon
                      onClick={() =>
                        setDepShape({ entity: department, visible: true })
                      }
                    />,
                  ]}
                >
                  {department.name}
                </List.Item>
              )}
              locale={{ emptyText: "Пока пусто" }}
            />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card
            title="Пользователи"
            bordered={false}
            extra={<AddIcon onClick={() => setUserShape({ visible: true })} />}
          >
            <List
              bordered
              loading={userLoading}
              dataSource={users}
              renderItem={(user) => (
                <List.Item
                  actions={[
                    <EditIcon
                      onClick={() =>
                        setUserShape({ entity: user, visible: true })
                      }
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<UserIcon user={user} />}
                    description={
                      user.Departments.length
                        ? user.Departments.map(({ name }) => name).join(", ")
                        : "Без подразделения"
                    }
                    title={user.name}
                  />
                </List.Item>
              )}
              locale={{ emptyText: "Пока пусто" }}
            />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card title="Настройка уведомлений" bordered={false}>
            <div>
              <Checkbox
                onChange={(e) => setInformMeAboutNewTasks(e.target.checked)}
                checked={informMeAboutNewTasks}
              >
                Информировать меня в телеграм о новых задачах
              </Checkbox>
            </div>
            <div>
              <Checkbox
                onChange={(e) => setInformMeAboutTaskEvents(e.target.checked)}
                checked={informMeAboutTaskEvents}
              >
                Информировать меня в телеграм о событиях в задачах
              </Checkbox>
            </div>
          </Card>
        </Col>
      </MainRow>
      <CreateEditDepartment
        open={depShape.visible}
        department={depShape.entity}
        onHide={() => setDepShape({ visible: false })}
      />
      <CreateEditUser
        open={userShape.visible}
        user={userShape.entity}
        onHide={() => setUserShape({ visible: false })}
      />
    </PageContainer>
  );
});

export default Settings;
