import React, { useEffect } from "react";
import List from "antd/es/list";
import TaskElement from "./TaskElement";
import { Pagination, Row } from "antd";
import Col from "antd/es/col";
import taskStore from "../../../store/task.store";
import { TASKS_PER_PAGE } from "../../../util/const";
import { observer } from "mobx-react";

interface Props {}

const TaskList: React.FC<Props> = observer(() => {
  const { total } = taskStore.taskListData;
  const {
    taskListData: { loading, data },
  } = taskStore;

  useEffect(() => {
    taskStore.updateTaskFilter(undefined, true);
  }, []);

  return (
    <div>
      <List
        bordered
        dataSource={data}
        loading={loading}
        locale={{ emptyText: "Пока пусто" }}
        renderItem={(task) => <TaskElement task={task} />}
      />
      <Row justify="center">
        <Col>
          <Pagination
            defaultCurrent={1}
            pageSize={TASKS_PER_PAGE}
            onChange={(page) => taskStore.taskListFetch(page)}
            total={total}
          />
        </Col>
      </Row>
    </div>
  );
});

export default TaskList;
