import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

interface Props {
  global?: boolean;
}

const Loader: React.FC<Props> = ({ global = false }) => {
  return (
    <Container global={global}>
      <LoadingOutlined style={{ fontSize: 48 }} spin />
    </Container>
  );
};

export default Loader;
