import React from "react";
import PageContainer from "../../component/layout/PageContainer";
import Col from "antd/es/col";
import styled from "styled-components";
import Row from "antd/es/row";
import TaskList from "./component/TaskList";
import Card from "antd/es/card";
import Filter from "./component/Filter";
import { isMobile } from "react-device-detect";
import Dropdown from "antd/es/dropdown";
import Button from "antd/es/button";
import { Segmented } from "antd";
import TaskViewMode from "../../enum/TaskViewMode";
import TaskCalendar from "./component/TaskCalendar";
import { observer } from "mobx-react";
import taskStore from "../../store/task.store";

const MainRow = styled(Row)`
  width: 100%;
  padding: 10px;
`;

const FilterWrapper = styled.div`
  min-width: 90vw;
  max-width: 90vw;
  padding: 15px 10px;
  background-color: white;
  box-shadow: 0px 1px 51px -7px rgba(0, 0, 0, 0.68);
`;

interface Props {}

const Home: React.FC<Props> = observer(() => {
  const { viewMode, setViewMode } = taskStore;
  return (
    <PageContainer title="Home page">
      <MainRow gutter={[12, 12]}>
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Card
            title="Задачи"
            bordered={false}
            extra={
              isMobile ? (
                <Dropdown
                  dropdownRender={(menu) => (
                    <FilterWrapper>
                      <Filter />
                    </FilterWrapper>
                  )}
                >
                  <Button type="link">Фильтр</Button>
                </Dropdown>
              ) : (
                <Segmented
                  onChange={(value) => setViewMode(value as TaskViewMode)}
                  options={[
                    { label: "Список", value: TaskViewMode.LIST },
                    { label: `Календарь`, value: TaskViewMode.CALENDAR },
                  ]}
                />
              )
            }
          >
            {viewMode === TaskViewMode.LIST ? <TaskList /> : <TaskCalendar />}
          </Card>
        </Col>
        {!isMobile && (
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            {!isMobile && (
              <Card title="Фильтр">
                <Filter />
              </Card>
            )}
          </Col>
        )}
      </MainRow>
    </PageContainer>
  );
});

export default Home;
