import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import departmentStore from "../../store/department.store";
import userStore from "../../store/user.store";
import TreeSelect from "antd/es/tree-select";
import User from "../../type/entity/User";
import Department from "../../type/entity/Department";

interface Props {
  allowClear?: boolean;
  departmentId?: Department["id"];
  disabled?: boolean;
  onChange: (departmentId?: Department["id"], userId?: User["id"]) => void;
  placeholder?: string;
  userId?: User["id"];
}

const DepartmentUserSelect: React.FC<Props> = observer(
  ({ allowClear, departmentId, disabled, onChange, placeholder, userId }) => {
    const items = useMemo(() => {
      return departmentStore.departmentListData.data.map((department) => ({
        label: department.name,
        value: department.id.toString(),
        children: userStore.userListData.data
          .filter(({ Departments }) =>
            Departments.some(({ id }) => id === department.id)
          )
          .map((user) => ({
            label: (
              <div>
                <div>{user.name}</div>
                {user.Departments.length > 1 && (
                  <i>В составе {department.name}</i>
                )}
              </div>
            ),
            value: `${department.id}-${user.id}`,
          })),
      }));
    }, []);

    const value = useMemo(() => {
      if (departmentId) {
        return `${departmentId}${userId ? `-${userId}` : ""}`;
      }
      return undefined;
    }, [departmentId, userId]);

    const handleChange = useCallback(
      (value?: string) => {
        if (value) {
          const parts = value.split("-");
          onChange(
            parseInt(parts[0], 10),
            parts.length > 1 ? parseInt(parts[1], 10) : undefined
          );
        } else {
          onChange(undefined, undefined);
        }
      },
      [onChange]
    );

    return (
      <TreeSelect
        allowClear={allowClear}
        disabled={disabled}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        onChange={handleChange}
        placeholder={placeholder ?? "Подразделение / Сотрудник"}
        style={{ width: "100%" }}
        treeData={items}
        treeDefaultExpandAll
        value={value}
      />
    );
  }
);

export default DepartmentUserSelect;
