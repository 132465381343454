import React, { useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Alert, Col, Radio, Space } from "antd";
import Row from "antd/es/row";
import Button from "antd/es/button";
import styled from "styled-components";
import TaskSaveMode from "../../../enum/TaskSaveMode";

const Container = styled.div`
  margin-bottom: 8px;
`;

interface Props {
  isCanceled: boolean;
  isPeriodicChange: boolean;
  onHide: () => void;
  onSave: (mode: TaskSaveMode) => void;
}

const PeriodicalSaveWarningModal: React.FC<Props> = ({
  isCanceled,
  isPeriodicChange,
  onHide,
  onSave,
}) => {
  const [mode, setMode] = useState<TaskSaveMode>(TaskSaveMode.REGULAR);
  return (
    <Modal
      footer={null}
      onCancel={onHide}
      open
      title="Сохранение периодической задачи"
    >
      <Container>
        {isPeriodicChange || isCanceled ? (
          <Alert
            description={
              isCanceled
                ? "При отмене периодической задачи ВСЕ экземпляры будут отменены и больше создаваться не будут."
                : "Вы изменили периодичность задачи. Существующие экземпляры задачи, кроме оригинала будут отменены, а затем если выбрана другая периодичность будут созданы новые экземпляры."
            }
            showIcon
            type="error"
          />
        ) : (
          <Alert
            description="Вы изменили периодическую задачу, вы можете выбрать какие экзепляры будут обновлены"
            showIcon
            type="warning"
          />
        )}
      </Container>
      {!(isPeriodicChange || isCanceled) && (
        <Radio.Group
          onChange={({ target: { value } }) => setMode(value)}
          value={mode}
        >
          <Space direction="vertical">
            <Radio value={TaskSaveMode.REGULAR}>Только эта задача</Radio>
            <Radio value={TaskSaveMode.FUTURE}>Эта и все будущие</Radio>
            <Radio value={TaskSaveMode.ALL}>Все существующие</Radio>
          </Space>
        </Radio.Group>
      )}
      <Row gutter={4} justify="end">
        <Col>
          <Button
            danger={isPeriodicChange || isCanceled}
            type="primary"
            onClick={() => onSave(mode)}
          >
            Подтвердить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default PeriodicalSaveWarningModal;
