import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import UserRole from "../../enum/UserRole";
import { observer } from "mobx-react";
import authStore from "../../store/auth.store";

interface SecureRouteProps extends RouteProps {}

const AdminRoute: React.FC<SecureRouteProps> = observer(
  ({ children, ...rest }) => {
    const { currentUser } = authStore;
    return (
      <Route
        {...rest}
        // @ts-ignore
        render={() =>
          currentUser?.roleId === UserRole.ADMIN ? (
            children
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  }
);

export default AdminRoute;
