import React, { useCallback, useEffect, useMemo, useState } from "react";
import taskStore from "../../../store/task.store";
import { observer } from "mobx-react";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import { Col, Row } from "antd/es/grid";
import Button from "antd/es/button";
import {
  DownCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  RightOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import Loader from "../../../component/misc/Loader";
import Task from "../../../type/entity/Task";
import Holidays from "date-holidays";
import getWeekDayNumber from "../../../util/getWeekDayNumber";

const hd = new Holidays("RU");

interface Holiday {
  day: number;
  name: string;
}

const DayHeader = styled(Row)`
  height: 24px;
`;

const DueIcon = styled(DownCircleOutlined)`
  color: red;
  font-size: 12px;
`;

const StartIcon = styled(UpCircleOutlined)`
  color: green;
`;

const TodayName = styled(Col)`
  color: red;
  font-weight: bold;
`;

const MonthName = styled(Col)`
  text-transform: uppercase;
`;

const Day = styled(Col)<{ isHoliday: boolean }>`
  flex: 1;
  min-height: 120px;
  max-height: 120px;
  overflow: auto;
  padding: 8px;
  border-bottom-right-radius: 16px;
  background-color: ${(props) => (props.isHoliday ? "#f1e5e9" : "inherit")};
  &:hover {
    background-color: bisque;
  }
`;

const TaskName = styled(Col)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
`;

const Event = styled.div<{ isHoliday?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  border: 0.5px grey solid;
  cursor: pointer;
  font-size: 10px;
  background-color: ${(props) => (props.isHoliday ? "#f1dada" : "inherit")};
  &:hover {
    background-color: aliceblue;
  }
`;

interface Props {}

const TaskCalendar: React.FC<Props> = observer(() => {
  const [hoveredDay, setHoveredDay] = useState<number | undefined>();
  const { monthOffset, setMonthOffset } = taskStore;
  const relativeDay = useMemo(
    () => dayjs().add(monthOffset, "month"),
    [monthOffset]
  );
  const {
    taskCalendarData: { loading, data },
  } = taskStore;

  const getDayTasks = useCallback(
    (dayNumber: number) => {
      const day = relativeDay.set("D", dayNumber);

      return data.filter(
        (task) =>
          (!!task.periodType && day.isSame(dayjs(task.startAt), "day")) ||
          (!task.periodType && day.isSame(dayjs(task.dueDate), "day"))
      );
    },
    [data, relativeDay]
  );

  const elements: number[][] = useMemo(() => {
    let count = relativeDay.daysInMonth();
    let firstDayNumber = getWeekDayNumber(relativeDay.startOf("month"));
    const result = [[...new Array(firstDayNumber - 1).fill("0").map((_) => 0)]];
    let currentIndex = 0;
    for (let i = 1; i <= count; i++) {
      result[currentIndex].push(i);
      if (result[currentIndex].length >= 7) {
        currentIndex++;
        result.push([]);
      }
    }
    if (result[currentIndex].length) {
      for (let i = result[currentIndex].length; i < 7; i++) {
        result[currentIndex].push(0);
      }
    }

    return result;
  }, [relativeDay]);

  const handleClick = useCallback((task: Task) => {
    taskStore.setTaskModalState({ visible: true, task });
  }, []);

  useEffect(() => {
    taskStore.updateTaskFilter(undefined, true);
  }, []);

  const monthHolidays = useMemo(() => {
    const holidays = hd.getHolidays(parseInt(relativeDay.format("YYYY"), 10));

    const result: Holiday[] = [];
    holidays.forEach(({ start, end, name }) => {
      const startDay = dayjs(start);
      const length = dayjs(end).diff(startDay, "day");
      for (let i = 0; i < length; i++) {
        const dayOfHoliday = startDay.add(i, "day");
        if (dayOfHoliday.isSame(relativeDay, "month")) {
          result.push({
            day: parseInt(dayOfHoliday.format("D")),
            name,
          });
        }
      }
    });
    return result;
  }, [relativeDay]);

  const getDayHolidays = useCallback(
    (dayNumber: number) => {
      return monthHolidays.filter(({ day }) => day === dayNumber);
    },
    [monthHolidays]
  );

  const handleCreateTask = useCallback((day: Dayjs) => {
    taskStore.setTaskModalState({ visible: true, defaultVisibleDate: day });
  }, []);

  return (
    <div>
      <Row align="middle" gutter={8} justify="center">
        <Col>
          <Button
            icon={<LeftOutlined />}
            onClick={() => setMonthOffset(monthOffset - 1)}
            type="link"
          />
        </Col>
        <MonthName>{relativeDay.format("MMMM YYYY")}</MonthName>
        <Col>
          <Button
            icon={<RightOutlined />}
            onClick={() => setMonthOffset(monthOffset + 1)}
            type="link"
          ></Button>
        </Col>
      </Row>
      <hr />
      {loading ? (
        <Loader />
      ) : (
        elements.map((subElements, i) => (
          <Row key={`row-${i}`} gutter={4} justify="space-between">
            {subElements.map((subElement, j) => {
              const holidays = getDayHolidays(subElement);
              const day = relativeDay.set("D", subElement);

              return (
                <Day
                  key={`col-${i}-${j}`}
                  onMouseOver={() => setHoveredDay(subElement)}
                  onMouseLeave={() => setHoveredDay(undefined)}
                  isHoliday={
                    subElement > 0 &&
                    (!!holidays.length ||
                      [0, 6].includes(parseInt(day.format("d"))))
                  }
                >
                  <DayHeader align="middle" justify="space-between">
                    <Col>
                      {day.isSame(dayjs(), "d") ? (
                        <TodayName>{subElement}</TodayName>
                      ) : (
                        <b>{subElement > 0 ? subElement : ""}</b>
                      )}
                    </Col>
                    {hoveredDay === subElement &&
                      subElement > 0 &&
                      day.startOf("day").diff(dayjs().startOf("day"), "day") >
                        0 && (
                        <Col>
                          <Button
                            size="small"
                            icon={<PlusCircleOutlined />}
                            onClick={() => handleCreateTask(day)}
                            type="link"
                          />
                        </Col>
                      )}
                  </DayHeader>
                  {getDayTasks(subElement).map((task) => (
                    <Event onClick={() => handleClick(task)}>
                      <Row align="middle" justify="space-between">
                        <TaskName span={20}>{task.text}</TaskName>
                        <Col span={4}>
                          {!!task.periodType ? <StartIcon /> : <DueIcon />}
                        </Col>
                      </Row>
                    </Event>
                  ))}
                  {holidays.map(({ name }) => (
                    <Event isHoliday>{name}</Event>
                  ))}
                </Day>
              );
            })}
          </Row>
        ))
      )}
    </div>
  );
});

export default TaskCalendar;
