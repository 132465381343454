import { ManipulateType } from "dayjs";

enum TaskPeriod {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export const getTaskPeriodUnit = (period: TaskPeriod): ManipulateType => {
  if (period === TaskPeriod.DAY) {
    return "d";
  }
  if (period === TaskPeriod.WEEK) {
    return "w";
  }
  if (period === TaskPeriod.MONTH) {
    return "M";
  }
  return "y";
};

export const getTaskPeriodName = (period: TaskPeriod): string => {
  if (period === TaskPeriod.DAY) {
    return "Ежедневно";
  }
  if (period === TaskPeriod.WEEK) {
    return "Еженедельно";
  }
  if (period === TaskPeriod.MONTH) {
    return "Ежемесячно";
  }
  return "Ежегодно";
};

export default TaskPeriod;
