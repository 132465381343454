import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import taskStore from "../../../store/task.store";
import TaskEventType from "../../../enum/TaskEventType";
import AntdTimeline from "antd/es/timeline";
import { TimelineItemProps } from "antd/es/timeline/TimelineItem";
import {
  getTaskStatusColor,
  getTaskStatusName,
} from "../../../enum/TaskStatus";
import dayjs from "dayjs";
import TaskEvent from "../../../type/entity/TaskEvent";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 6px;
`;

interface Props {}

const Timeline: React.FC<Props> = observer(() => {
  const events = taskStore.eventsListData.data;
  const task = taskStore.taskModalState.task;
  const items = useMemo(() => {
    if (!task) {
      return;
    }
    let moment = dayjs(task.createdAt);

    const result: TimelineItemProps[] = [];
    (Array.from(events) as TaskEvent[])
      .reverse()
      .filter(({ typeId }) => typeId === TaskEventType.STATUS)
      .forEach(({ statusId, createdAt }) => {
        const createdAtDayjs = dayjs(createdAt);
        result.push({
          color: getTaskStatusColor(statusId!),
          children: (
            <div>
              <div>{`Статус: ${getTaskStatusName(statusId)}. (${moment.to(
                createdAtDayjs
              )})`}</div>
              <i>{createdAtDayjs.format("L LT")}</i>
            </div>
          ),
        });
        moment = createdAtDayjs;
      });
    return [
      {
        color: "black",
        children: `Задача создана: ${dayjs(task.createdAt).format("L LT")}`,
      },
      ...result,
    ];
  }, [events, task]);

  return (
    <Container>
      <AntdTimeline items={items} />
    </Container>
  );
});

export default Timeline;
