import React, { useCallback, useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Col, Input, Row, Select } from "antd";
import { observer } from "mobx-react-lite";
import User from "../../type/entity/User";
import userStore from "../../store/user.store";
import Button from "antd/es/button";
import randomString from "../../util/randomString";
import styled from "styled-components";
import departmentStore from "../../store/department.store";
import Department from "../../type/entity/Department";
import notification from "antd/es/notification";
import FormRow from "../layout/FormRow";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  onHide: () => void;
  open: boolean;
  user?: User;
}

const CreateEditUser: React.FC<Props> = observer(
  ({ onHide, open = false, user }) => {
    const departments = departmentStore.departmentListData.data;
    const { createUser, updateUser, userSaving } = userStore;
    const [name, setName] = useState<string>("");
    const [telegramId, setTelegramId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [departmentIds, setDepartmentIds] = useState<Department["id"][]>([]);

    useEffect(() => {
      if (open) {
        setName(user?.name ?? "");
        setPassword(user?.password ?? "");
        setDepartmentIds(user?.Departments.map(({ id }) => id) ?? []);
        setTelegramId(user?.telegramId ?? "");
      }
    }, [user, open, setName, setPassword, setDepartmentIds, setTelegramId]);

    const handleSave = useCallback(async () => {
      if (name.trim().length === 0) {
        notification.error({ message: "Имя не должно быть пустым" });
        return;
      }
      if (password.trim().length === 0) {
        notification.error({ message: "Пароль не должно быть пустым" });
        return;
      }
      const payload = { name, password, departmentIds, telegramId };
      const onSuccess = () => {
        userStore.userListFetch();
        onHide();
      };

      await (user
        ? updateUser(user.id, payload, onSuccess)
        : createUser(payload, onSuccess));
    }, [
      createUser,
      departmentIds,
      name,
      onHide,
      password,
      telegramId,
      updateUser,
      user,
    ]);

    return (
      <Modal
        title={user ? name : "Создать пользователя"}
        open={open}
        onOk={handleSave}
        okText={user ? "Сохранить изменения" : "Создать пользователя"}
        okButtonProps={{ loading: userSaving }}
        cancelText="Закрыть"
        onCancel={onHide}
      >
        <Content>
          <Input
            onChange={(e) => setName(e.target.value)}
            placeholder="Имя и фамилия"
            value={name}
          />
          <Row gutter={12}>
            <Col>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Пароль"
                value={password}
              />
            </Col>
            <Col>
              <Button onClick={() => setPassword(randomString(5))}>
                Сгенерировать
              </Button>
            </Col>
          </Row>
          <Select
            options={departments.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={setDepartmentIds}
            mode="multiple"
            value={departmentIds}
          />
          <FormRow
            node={
              <Input
                onChange={(e) => setTelegramId(e.target.value)}
                prefix="@"
                placeholder="Введите телеграм ID"
                value={telegramId}
              />
            }
            title="Телеграм ID"
          />
        </Content>
      </Modal>
    );
  }
);

export default CreateEditUser;
