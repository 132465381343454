import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, DatePicker, Input, InputNumber, Select } from "antd";
import { observer } from "mobx-react-lite";
import Department from "../../../type/entity/Department";
import notification from "antd/es/notification";
import taskStore from "../../../store/task.store";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import styled from "styled-components";
import TaskStatus, { getTaskStatusName } from "../../../enum/TaskStatus";
import dayjs, { Dayjs } from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import Checkbox from "antd/es/checkbox/Checkbox";
import TaskPeriod, {
  getTaskPeriodName,
  getTaskPeriodUnit,
} from "../../../enum/TaskPeriod";
import FormRow from "../../layout/FormRow";
import { TaskPayload } from "../../../type/entity/Task";
import DepartmentUserSelect from "../../layout/DepartmentUserSelect";
import User from "../../../type/entity/User";
import { DATE_FORMAT } from "../../../util/const";
import authStore from "../../../store/auth.store";
import UserRole from "../../../enum/UserRole";
import PeriodicalSaveWarningModal from "./PeriodicalSaveWarningModal";
import useBoolean from "../../../util/hook/useBoolean";
import TaskSaveMode from "../../../enum/TaskSaveMode";
import TaskViewMode from "../../../enum/TaskViewMode";

const { TextArea } = Input;

const disabledDueDate: RangePickerProps["disabledDate"] = (current) =>
  current && current < dayjs().endOf("day");

const disabledStartDate: RangePickerProps["disabledDate"] = (current) =>
  current && current <= dayjs().endOf("day");

const DEFAULT_DUE_DATE = dayjs().add(1, "day");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

interface Props {
  onCLose: () => void;
}

const General: React.FC<Props> = observer(({ onCLose }) => {
  const { visible, task, defaultVisibleDate } = taskStore.taskModalState;

  const [text, setText] = useState<string>("");
  const [dueDate, setDueDate] = useState<Dayjs>(
    defaultVisibleDate ?? DEFAULT_DUE_DATE
  );
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.TO_DO);
  const [period, setPeriod] = useState<TaskPeriod | undefined>();
  const [startAt, setStartAt] = useState<Dayjs>(dayjs());
  const [departmentId, setDepartmentId] = useState<
    Department["id"] | undefined
  >();
  const [executorId, setExecutorId] = useState<User["id"] | undefined>();
  const [periodicalModalVisible, showPeriodicalModal, hidePeriodicalModal] =
    useBoolean();

  const daysCount = useMemo(() => {
    return dueDate
      .startOf("d")
      .diff(dayjs(startAt ?? new Date()).startOf("d"), "d");
  }, [dueDate, startAt]);

  const taskSaving = taskStore.taskSaving;

  const genericFieldsChanged = useMemo(() => {
    return (
      departmentId !== task?.departmentId ||
      executorId !== task?.executorId ||
      text !== task?.text ||
      !dayjs(task?.dueDate).isSame(dueDate)
    );
  }, [task, departmentId, dueDate, executorId, text]);

  const periodFieldsChanged = useMemo(() => {
    return period !== task?.periodType || !dayjs(task?.startAt).isSame(startAt);
  }, [task, period, startAt]);

  const anyChanges = useMemo(() => {
    return (
      !task?.id ||
      (!!task?.id && genericFieldsChanged) ||
      periodFieldsChanged ||
      task.statusId !== status
    );
  }, [
    genericFieldsChanged,
    periodFieldsChanged,
    status,
    task?.id,
    task?.statusId,
  ]);

  const handleChangeDaysCount = useCallback(
    (daysCount: number) => {
      setDueDate(startAt.add(daysCount, "d"));
    },
    [startAt, setDueDate]
  );

  const nextPeriods = useMemo(() => {
    if (!period) {
      return [];
    }
    return [1, 2, 3].map((count) =>
      startAt.add(count, getTaskPeriodUnit(period)).format("dddd, LL")
    );
  }, [period, startAt]);

  useEffect(() => {
    if (visible) {
      setDepartmentId(task?.departmentId);
      setDueDate(
        task?.dueDate
          ? dayjs(task.dueDate)
          : defaultVisibleDate ?? DEFAULT_DUE_DATE
      );
      setExecutorId(task?.executorId);
      setPeriod(task?.periodType);
      setStartAt(dayjs(task?.startAt ?? new Date()));
      setStatus(task?.statusId ?? TaskStatus.TO_DO);
      setText(task?.text ?? "");
    }
  }, [
    defaultVisibleDate,
    setDepartmentId,
    setDueDate,
    setExecutorId,
    setPeriod,
    setStartAt,
    setStatus,
    setText,
    task,
    visible,
  ]);

  const taskPropertyDisabled = useMemo(() => {
    return (
      task &&
      !(
        authStore.currentUser?.roleId === UserRole.ADMIN ||
        authStore.currentUser?.id === task?.User.id
      )
    );
  }, [task]);

  const taskPeriodicalPropertyDisabled: boolean = useMemo(() => {
    return !!task?.periodType && !!task.originalId;
  }, [task]);

  const saveTask = useCallback(
    async (mode: TaskSaveMode) => {
      const taskPayload: TaskPayload = {
        text,
        departmentId,
        executorId: executorId ?? null,
        statusId: status,
        dueDate: dueDate?.toISOString(),
        startAt: startAt?.toISOString(),
        daysCount,
        periodType: period,
      };
      await (task
        ? taskStore.updateTask(task.id, taskPayload, mode)
        : taskStore.createTask(taskPayload));
      (taskStore.viewMode === TaskViewMode.LIST
        ? taskStore.taskListFetch
        : taskStore.taskCalendarFetch)();
      onCLose();
    },
    [
      daysCount,
      departmentId,
      dueDate,
      executorId,
      onCLose,
      period,
      startAt,
      status,
      task,
      text,
    ]
  );

  const handleSave = useCallback(() => {
    if (text.trim().length === 0) {
      notification.error({ message: "Текст задачи не может быть пустым" });
      return;
    }
    if (!departmentId) {
      notification.error({ message: "Подразделение не выбрано" });
      return;
    }
    if (!!task?.periodType && (periodFieldsChanged || genericFieldsChanged)) {
      showPeriodicalModal();
      return;
    }
    saveTask(TaskSaveMode.REGULAR);
  }, [
    text,
    departmentId,
    task?.periodType,
    saveTask,
    showPeriodicalModal,
    periodFieldsChanged,
    genericFieldsChanged,
  ]);

  useEffect(() => {
    if (task?.id) {
      taskStore.eventListFetch(task.id);
    }
  }, [task]);

  return (
    <Container>
      <TextArea
        disabled={taskPropertyDisabled}
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      {task?.id && (
        <FormRow
          node={
            <Select
              options={[
                TaskStatus.TO_DO,
                TaskStatus.IN_PROGRESS,
                TaskStatus.DONE,
                TaskStatus.CLOSED,
                ...(authStore.currentUser?.roleId === UserRole.ADMIN
                  ? [TaskStatus.CANCELED]
                  : []),
              ].map((statusId) => ({
                label: getTaskStatusName(statusId),
                value: statusId,
              }))}
              onChange={setStatus}
              style={{ width: "100%" }}
              value={status}
            />
          }
          title="Статус"
        />
      )}
      <FormRow
        node={
          <DepartmentUserSelect
            disabled={taskPropertyDisabled}
            departmentId={departmentId}
            onChange={(departmentId, userId) => {
              setDepartmentId(departmentId);
              setExecutorId(userId);
            }}
            userId={executorId}
          />
        }
        title="Назначение"
      />
      <FormRow
        node={
          <DatePicker
            allowClear={false}
            disabledDate={disabledDueDate}
            disabled={!!period || taskPropertyDisabled}
            format={DATE_FORMAT}
            onChange={(date) => setDueDate(date as Dayjs)}
            value={dueDate}
          />
        }
        title="Дата завершения"
      />
      {task?.periodType && task.originalId && (
        <Alert
          description="Это дубликат оригинальной периодической задачи. Вы не можете менять периодичность здесь"
          showIcon
          type="warning"
        />
      )}
      <Row align="middle" justify="space-between">
        <Col>
          <Checkbox
            disabled={taskPropertyDisabled || taskPeriodicalPropertyDisabled}
            onChange={({ target: { checked } }) => {
              setStartAt(dayjs());
              setDueDate(DEFAULT_DUE_DATE);
              setPeriod(checked ? TaskPeriod.WEEK : undefined);
            }}
            checked={!!period}
          >
            Периодическа задача
          </Checkbox>
        </Col>
      </Row>
      {period && (
        <FormRow
          node={
            <Select
              disabled={taskPropertyDisabled || taskPeriodicalPropertyDisabled}
              options={[
                TaskPeriod.DAY,
                TaskPeriod.WEEK,
                TaskPeriod.MONTH,
                TaskPeriod.YEAR,
              ].map((p) => ({
                value: p,
                label: getTaskPeriodName(p),
              }))}
              onChange={setPeriod}
              style={{ width: "100%" }}
              value={period}
            />
          }
          title="Периодичность"
        />
      )}
      {period && (
        <FormRow
          node={
            <DatePicker
              allowClear={false}
              disabledDate={disabledStartDate}
              disabled={taskPropertyDisabled || taskPeriodicalPropertyDisabled}
              format={DATE_FORMAT}
              onChange={(date) => {
                setStartAt(date as Dayjs);
                setDueDate((date as Dayjs).add(daysCount, "d"));
              }}
              value={startAt}
            />
          }
          title="Старт задачи"
        />
      )}
      {period && (
        <FormRow
          node={
            <InputNumber
              disabled={taskPropertyDisabled || taskPeriodicalPropertyDisabled}
              min={1}
              onChange={(value) => handleChangeDaysCount(value ?? 1)}
              value={daysCount}
            />
          }
          title="Дней на выполнение"
        />
      )}
      {period && !taskPropertyDisabled && (
        <Alert
          message="Последующие периоды"
          description={
            <ul>
              {nextPeriods.map((item) => (
                <li>{item}</li>
              ))}
              <li>...</li>
            </ul>
          }
          type="info"
        />
      )}
      <Row justify="end">
        <Col>
          <Button
            disabled={!anyChanges}
            loading={taskSaving}
            onClick={handleSave}
            type="primary"
          >
            {task ? "Сохранить изменения" : "Создать задачу"}
          </Button>
        </Col>
      </Row>
      {periodicalModalVisible && (
        <PeriodicalSaveWarningModal
          isCanceled={
            status === TaskStatus.CANCELED &&
            task?.statusId !== TaskStatus.CANCELED
          }
          isPeriodicChange={periodFieldsChanged}
          onHide={hidePeriodicalModal}
          onSave={saveTask}
        />
      )}
    </Container>
  );
});

export default General;
