import { action, makeObservable, observable } from "mobx";
import User, { UserPayload } from "../type/entity/User";
import NetworkListData from "../type/NetworkListData";
import initializeNetworkListData from "../util/initializeNetworkListData";
import userService from "../service/network/user.service";

export class UserStore {
  userListData: NetworkListData<User> = initializeNetworkListData<User>();
  userListUpdater(networkData: NetworkListData<User>) {
    this.userListData = networkData;
  }
  userListFetch() {
    userService.fetchNetworkListData<User>(this.userListUpdater);
  }

  userSaving: boolean = false;
  async createUser(user: UserPayload, onSuccess?: () => void) {
    this.userSaving = true;
    try {
      const response = await userService.post(user);
      this.userSaving = false;
      onSuccess?.();
      return response;
    } catch (e) {
      this.userSaving = false;
    }
  }
  async updateUser(id: User["id"], user: UserPayload, onSuccess?: () => void) {
    this.userSaving = true;
    try {
      const response = await userService.put(user, id);
      this.userSaving = false;
      onSuccess?.();
      return response;
    } catch (e) {
      this.userSaving = false;
    }
  }
  async updateUserMetadata(id: User["id"], metadata: string) {
    return await userService.put({ metadata }, id);
  }

  constructor() {
    makeObservable(this, {
      userSaving: observable,
      userListData: observable,
      userListUpdater: action.bound,
      createUser: action.bound,
      updateUser: action.bound,
    });
  }
}

const userStore = new UserStore();

export default userStore;
