import React, { useCallback, useMemo } from "react";
import { Col, Dropdown } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import authStore from "../../store/auth.store";
import styled from "styled-components";
import Button from "antd/es/button";
import { MenuProps } from "antd/lib";
import UserRole from "../../enum/UserRole";
import CreateEditTask from "../misc/CreateEditTask";
import Path from "../../enum/Path";
import logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";
import taskStore from "../../store/task.store";
import UserIcon from "../UserIcon";
import Row from "antd/es/row";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

const TopNav = styled.div`
  padding-top: 8px;
  height: 45px;
  width: 100%;
  top: 0;
  background-color: white;
  font-weight: 600;
  position: fixed;
  & > div,
  button {
    margin: 0 10px;
  }
  z-index: 9;
`;

const Logo = styled.img`
  height: 30px;
`;

const Content = styled.div`
  width: 100vw;
  padding: 55px 0px 10px;
  height: 100%;
  background-color: #a49c9c;
  overflow: scroll;
`;

interface Props {
  children: React.ReactNode;
  title: string;
}

const PageContainer: React.FC<Props> = observer(({ children, title }) => {
  const { currentUser, logout } = authStore;
  const taskModalVisible = taskStore.taskModalState.visible;

  const history = useHistory();

  const handleCreateTask = useCallback(() => {
    taskStore.setTaskModalState({ visible: true });
  }, []);

  const items: MenuProps["items"] = useMemo(() => {
    const menu = [];
    if (currentUser?.roleId === UserRole.ADMIN) {
      menu.push({
        key: "1",
        label: <span>Настройки</span>,
        onClick: () => history.push(Path.SETTINGS),
      });
    }
    menu.push({
      key: "2",
      label: <span>Выйти</span>,
      onClick: logout,
    });
    return menu;
  }, [currentUser, history, logout]);
  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Container>
        <TopNav>
          <Row align="middle" justify="space-between">
            <Col span={8}>
              <Button icon={<PlusOutlined />} onClick={handleCreateTask}>
                Задача
              </Button>
            </Col>
            <Col span={8}>
              <Row align="middle" justify="center">
                <Col>
                  <LogoContainer onClick={() => history.push(Path.HOME)}>
                    <Logo alt="logo" src={logo} />
                    ПЛАТОН
                  </LogoContainer>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="middle" justify="end">
                <Col>
                  {" "}
                  <Dropdown menu={{ items }} placement="bottomRight">
                    <Button
                      icon={<UserIcon mini user={currentUser!} />}
                      type="link"
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </TopNav>
        <Content>{children}</Content>
      </Container>
      {taskModalVisible && <CreateEditTask />}
    </>
  );
});

export default PageContainer;
