import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface SecureRouteProps extends RouteProps {
  isAuth: boolean;
}

const SecureRoute: React.FC<SecureRouteProps> = React.memo(
  ({ children, isAuth, ...rest }) => {
    return (
      <Route
        {...rest}
        // @ts-ignore
        render={() => (isAuth ? children : <Redirect to="/login" />)}
      />
    );
  }
);

export default SecureRoute;
