import React, { useCallback, useState } from "react";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import notification from "antd/es/notification";
import taskStore from "../../../store/task.store";
import List from "antd/es/list";
import Button from "antd/es/button";
import TaskEventType from "../../../enum/TaskEventType";
import Event from "../Event";
import { SendOutlined } from "@ant-design/icons";

interface Props {}

const Comments: React.FC<Props> = observer(() => {
  const [comment, setComment] = useState<string>("");
  const { task } = taskStore.taskModalState;

  const events = taskStore.eventsListData.data;
  const eventsLoading = taskStore.eventsListData.loading;
  const eventSaving = taskStore.taskEventSaving;

  const handleComment = useCallback(async () => {
    if (comment.trim().length === 0) {
      notification.error({ message: "Комментарий не должен быть пустым" });
      return;
    }
    await taskStore.createTaskEvent(task!.id, TaskEventType.COMMENT, comment);
    setComment("");
  }, [comment, task, setComment]);

  return (
    <div>
      <Input.Group compact>
        <Input
          onChange={(e) => setComment(e.target.value)}
          placeholder="Новый Комментарий"
          value={comment}
          style={{ width: "calc(100% - 50px)" }}
        />
        <Button
          icon={<SendOutlined />}
          style={{ width: "50px" }}
          onClick={handleComment}
          type="primary"
        />
      </Input.Group>

      <List
        bordered
        loading={eventSaving || eventsLoading}
        dataSource={events}
        renderItem={(event) => <Event event={event} />}
        locale={{ emptyText: "Пока никто не коментировал" }}
      />
    </div>
  );
});

export default Comments;
