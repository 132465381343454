import React from "react";
import { RouteProps } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

const NotStarted = styled.span`
  color: rgba(58, 192, 146, 0.84);
`;
const OutDated = styled.span`
  color: red;
`;

interface DueDateStateProps extends RouteProps {
  startDate?: Date;
  dueDate?: Date;
}

const DueDateState: React.FC<DueDateStateProps> = ({ startDate, dueDate }) => {
  if (!dueDate) {
    return null;
  }
  const diff = dayjs(dueDate).startOf("d").diff(dayjs().startOf("d"), "d");
  const startDateDiff = dayjs(startDate)
    .startOf("d")
    .diff(dayjs().startOf("d"), "d");
  return startDateDiff > 0 ? (
    <NotStarted>
      Дней до старта задачи <b>: {Math.abs(startDateDiff)}</b>
    </NotStarted>
  ) : (
    <div>
      {diff >= 0 ? "Дней до заверешия" : <OutDated>Просрочено дней</OutDated>}
      <b>: {Math.abs(diff)}</b>
    </div>
  );
};

export default DueDateState;
