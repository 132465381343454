import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";

interface Props {
  title: string;
  node: React.ReactNode;
}

const FormRow: React.FC<Props> = ({ title, node }) => (
  <Row align="middle" justify="space-between">
    <Col span={10}>{title}</Col>
    <Col span={14}>{node}</Col>
  </Row>
);

export default FormRow;
