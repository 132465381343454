import { action, makeObservable, observable } from "mobx";
import NetworkListData from "../type/NetworkListData";
import initializeNetworkListData from "../util/initializeNetworkListData";
import Task, { TaskFilter, TaskPayload, TaskRaw } from "../type/entity/Task";
import taskService from "../service/network/task.service";
import TaskStatus from "../enum/TaskStatus";
import defaultDataMapper from "../util/defaultDataMapper";
import TaskEvent from "../type/entity/TaskEvent";
import TaskEventType from "../enum/TaskEventType";
import dayjs, { Dayjs } from "dayjs";
import { TASKS_PER_PAGE } from "../util/const";
import deepEqual from "deep-equal";
import TaskSaveMode from "../enum/TaskSaveMode";
import TaskViewMode from "../enum/TaskViewMode";

export interface TaskModalState {
  visible: boolean;
  task?: Task;
  defaultVisibleDate?: Dayjs;
}

const taskMapper = (task: TaskRaw) => ({
  ...defaultDataMapper(task),
  dueDate: task.dueDate ? dayjs(task.dueDate).toDate() : undefined,
  startAt: task.startAt ? dayjs(task.startAt).toDate() : undefined,
});

export class TaskStore {
  taskModalState: TaskModalState = {
    visible: false,
  };
  setTaskModalState(state: TaskModalState) {
    this.taskModalState = state;
  }

  monthOffset = 0;
  setMonthOffset(monthOffset: number) {
    this.monthOffset = monthOffset;
    this.taskCalendarFetch();
  }

  viewMode = TaskViewMode.LIST;
  setViewMode(viewMode: TaskViewMode) {
    this.viewMode = viewMode;
  }

  taskFilter: TaskFilter = {
    statusIds: [TaskStatus.TO_DO, TaskStatus.IN_PROGRESS],
  };

  taskListData: NetworkListData<Task> = initializeNetworkListData<Task>();
  taskListUpdater(networkData: NetworkListData<Task>) {
    this.taskListData = networkData;
  }
  taskListFetch(page: number = 1) {
    taskService.fetchNetworkListData<Task>(
      this.taskListUpdater,
      undefined,
      {
        ...this.taskFilter,
        limit: TASKS_PER_PAGE,
        offset: (page - 1) * TASKS_PER_PAGE,
      },
      taskMapper
    );
  }

  taskCalendarData: NetworkListData<Task> = initializeNetworkListData<Task>();
  taskCalendarUpdater(networkData: NetworkListData<Task>) {
    this.taskCalendarData = networkData;
  }
  taskCalendarFetch() {
    const day = dayjs().add(this.monthOffset, "month");
    taskService.fetchNetworkListData<Task>(
      this.taskCalendarUpdater,
      undefined,
      {
        ...this.taskFilter,
        dateFrom: day.startOf("month").toISOString(),
        dateTo: day.endOf("month").toISOString(),
      },
      taskMapper
    );
  }

  updateTaskFilter(filter?: TaskFilter, forceUpdate?: boolean) {
    const newFilter = { ...this.taskFilter, ...filter };
    if (!deepEqual(newFilter, this.taskFilter) || forceUpdate) {
      this.taskFilter = { ...this.taskFilter, ...filter };
      if (this.viewMode === TaskViewMode.LIST) {
        this.taskListFetch();
      } else {
        this.taskCalendarFetch();
      }
    }
  }

  eventsListData: NetworkListData<TaskEvent> =
    initializeNetworkListData<TaskEvent>();

  eventListUpdater(networkData: NetworkListData<TaskEvent>) {
    this.eventsListData = networkData;
  }
  eventListFetch(taskId: Task["id"]) {
    taskService.fetchNetworkListData<TaskEvent>(
      this.eventListUpdater,
      `${taskId}/events`,
      defaultDataMapper
    );
  }

  allEventsListData: NetworkListData<TaskEvent> =
    initializeNetworkListData<TaskEvent>();

  allEventListUpdater(allEventsListData: NetworkListData<TaskEvent>) {
    this.allEventsListData = allEventsListData;
  }
  allEventListFetch() {
    taskService.fetchNetworkListData<TaskEvent>(
      this.allEventListUpdater,
      `events`,
      undefined,
      defaultDataMapper
    );
  }

  taskEventSaving: boolean = false;
  async createTaskEvent(
    taskId: Task["id"],
    typeId: TaskEventType,
    text?: string,
    statusId?: TaskStatus
  ) {
    this.taskEventSaving = true;
    const { data } = await taskService.post(
      { text, taskId, typeId, statusId },
      "event"
    );
    this.taskEventSaving = false;
    this.eventListFetch(taskId);
    this.allEventListFetch();
    this.taskListFetch();
    this.setTaskModalState({ visible: true, task: data });
    return data;
  }

  taskSaving: boolean = false;

  async createTask(task: TaskPayload) {
    this.taskSaving = true;
    const response = await taskService.post(task);
    this.taskSaving = false;
    return response;
  }
  async updateTask(id: Task["id"], task: TaskPayload, mode: TaskSaveMode) {
    this.taskSaving = true;
    const response = await taskService.put({ ...task, mode }, id);
    this.taskSaving = false;
    return response;
  }

  constructor() {
    makeObservable(this, {
      allEventListUpdater: action.bound,
      allEventsListData: observable,
      createTask: action.bound,
      createTaskEvent: action.bound,
      eventListUpdater: action.bound,
      eventsListData: observable,
      setTaskModalState: action.bound,
      taskEventSaving: observable,
      taskFilter: observable,
      taskCalendarData: observable,
      taskListData: observable,
      viewMode: observable,
      monthOffset: observable,
      taskListUpdater: action.bound,
      taskCalendarUpdater: action.bound,
      taskModalState: observable,
      taskSaving: observable,
      updateTask: action.bound,
      updateTaskFilter: action.bound,
      setViewMode: action.bound,
      setMonthOffset: action.bound,
      taskCalendarFetch: action.bound,
      taskListFetch: action.bound,
    });
  }
}

const taskStore = new TaskStore();

export default taskStore;
