import React from "react";
import {
  CheckCircleOutlined,
  CloseOutlined,
  InboxOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

enum TaskStatus {
  TO_DO = 1,
  IN_PROGRESS = 2,
  DONE = 3,
  CLOSED = 4,
  CANCELED = 5,
}

export const getAllTaskStatuses = (): TaskStatus[] =>
  Object.values(TaskStatus).filter(
    (value) => parseInt(value.toString()) === value
  ) as TaskStatus[];

export const getTaskStatusColor = (statusId: TaskStatus) => {
  if (statusId === TaskStatus.TO_DO) {
    return "grey";
  }
  if (statusId === TaskStatus.IN_PROGRESS) {
    return "purple";
  }
  if (statusId === TaskStatus.DONE) {
    return "green";
  }
  if (statusId === TaskStatus.CANCELED) {
    return "red";
  }
  return "#8aadc2";
};

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: ${getTaskStatusColor(TaskStatus.TO_DO)};
`;

const StyledLoginOutlined = styled(LoginOutlined)`
  color: ${getTaskStatusColor(TaskStatus.IN_PROGRESS)};
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  color: ${getTaskStatusColor(TaskStatus.DONE)};
`;

const StyledInboxOutlined = styled(InboxOutlined)`
  color: ${getTaskStatusColor(TaskStatus.CLOSED)};
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  color: ${getTaskStatusColor(TaskStatus.CANCELED)};
`;

export const getTaskStatusName = (status?: TaskStatus): string => {
  if (!status) {
    return "";
  }
  if (status === TaskStatus.TO_DO) {
    return "В очереди";
  }
  if (status === TaskStatus.IN_PROGRESS) {
    return "В работе";
  }
  if (status === TaskStatus.CLOSED) {
    return "Закрыто";
  }
  if (status === TaskStatus.CANCELED) {
    return "Отменено";
  }
  return "Выполнено";
};

export const getTaskStatusIcon = (status: TaskStatus): React.ReactNode => {
  if (status === TaskStatus.TO_DO) {
    return <StyledQuestionCircleOutlined />;
  }
  if (status === TaskStatus.IN_PROGRESS) {
    return <StyledLoginOutlined />;
  }
  if (status === TaskStatus.CLOSED) {
    return <StyledInboxOutlined />;
  }
  if (status === TaskStatus.CANCELED) {
    return <StyledCloseOutlined />;
  }
  return <StyledCheckCircleOutlined />;
};

export default TaskStatus;
