import React from "react";
import { observer } from "mobx-react-lite";
import List from "antd/es/list";
import Row from "antd/es/row";
import Col from "antd/es/col";
import TaskEventType from "../../enum/TaskEventType";
import Typography from "antd/es/typography";
import { getTaskStatusName } from "../../enum/TaskStatus";
import TaskEvent from "../../type/entity/TaskEvent";
import UserIcon from "../UserIcon";
import dayjs from "dayjs";

const { Text } = Typography;

interface Props {
  event: TaskEvent;
  showTaskText?: boolean;
}

const Event: React.FC<Props> = observer(({ event, showTaskText }) => {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<UserIcon user={event.User} />}
        title={
          <Row justify="space-between">
            <Col>{event.User.name}</Col>
            <Col>
              <Text disabled>
                <Col>{dayjs(event.updatedAt).fromNow()}</Col>
              </Text>
            </Col>
          </Row>
        }
        description={
          <div>
            {showTaskText && (
              <div>
                <b>Задач: {event.Task.text}</b>
              </div>
            )}
            <div>
              {event.typeId === TaskEventType.COMMENT ? (
                event.text
              ) : (
                <i>{`Изменение статуса: ${getTaskStatusName(
                  event.statusId
                )}`}</i>
              )}
            </div>
          </div>
        }
      />
    </List.Item>
  );
});

export default Event;
