import React, { useCallback } from "react";
import List from "antd/es/list";
import Task from "../../../type/entity/Task";
import TaskStatus, { getTaskStatusIcon } from "../../../enum/TaskStatus";
import styled from "styled-components";
import Row from "antd/es/row";
import Col from "antd/es/col";
import taskStore from "../../../store/task.store";
import DueDateState from "../../../component/misc/DueDateState";
import dayjs from "dayjs";
import { FieldTimeOutlined } from "@ant-design/icons";
import { getTaskPeriodName } from "../../../enum/TaskPeriod";
import UserIcon from "../../../component/UserIcon";

const ListItem = styled(List.Item)`
  cursor: pointer;
  &:hover {
    background-color: bisque;
  }
`;

interface Props {
  task: Task;
}

const TaskElement: React.FC<Props> = ({ task }) => {
  const handleClick = useCallback(() => {
    taskStore.setTaskModalState({ visible: true, task });
  }, [task]);

  return (
    <ListItem onClick={handleClick}>
      <List.Item.Meta
        title={
          <Row align="middle" justify="space-between">
            <Col>
              <Row gutter={8}>
                <Col>{getTaskStatusIcon(task.statusId)}</Col>
                <Col>{task.text}</Col>
              </Row>
            </Col>
            {task.periodType && (
              <Col>
                <Row gutter={8}>
                  <Col>
                    <i>{getTaskPeriodName(task.periodType)}</i>
                  </Col>
                  <Col>
                    <FieldTimeOutlined />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        }
        description={
          <div>
            <Row justify="space-between">
              <Col>{dayjs(task.updatedAt).fromNow()}</Col>
              <Col>От: {task.User.name}</Col>
            </Row>
            <Row justify="space-between">
              <Col>
                {task.Executor ? (
                  <UserIcon showName micro user={task.Executor} />
                ) : (
                  task.Department.name
                )}
              </Col>
              <Col>
                {task.statusId !== TaskStatus.DONE && (
                  <DueDateState
                    dueDate={task.dueDate}
                    startDate={task.startAt}
                  />
                )}
              </Col>
            </Row>
          </div>
        }
      />
    </ListItem>
  );
};

export default TaskElement;
