import React, { useCallback, useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Input } from "antd";
import Department from "../../type/entity/Department";
import { observer } from "mobx-react-lite";
import departmentStore from "../../store/department.store";

interface Props {
  department?: Department;
  onHide: () => void;
  open: boolean;
}

const CreateEditDepartment: React.FC<Props> = observer(
  ({ onHide, open = false, department }) => {
    const { createDepartment, updateDepartment, departmentSaving } =
      departmentStore;
    const [name, setName] = useState<string>("");

    useEffect(() => {
      if (open) {
        setName(department?.name ?? "");
      }
    }, [department, open, setName]);

    const handleSave = useCallback(async () => {
      await (department
        ? updateDepartment(department.id, name)
        : createDepartment(name));
      departmentStore.departmentListFetch();
      onHide();
    }, [createDepartment, department, name, onHide, updateDepartment]);

    return (
      <Modal
        title={department ? name : "Создать подразделение"}
        open={open}
        onOk={handleSave}
        okText={department ? "Сохранить изменения" : "Создать подразделение"}
        okButtonProps={{ loading: departmentSaving }}
        cancelText="Закрыть"
        onCancel={onHide}
      >
        <Input
          onChange={(e) => setName(e.target.value)}
          placeholder="Название подразделения"
          value={name}
        />
      </Modal>
    );
  }
);

export default CreateEditDepartment;
