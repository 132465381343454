import { SyntheticEvent, useCallback, useState } from "react";

type AnyEvent = Event | SyntheticEvent;

const useBoolean = (
  initialValue: boolean = false, // initial value
  stopPropagation: boolean = false // for convenient usage of setToTrue/False in some callbacks
): [
  boolean,
  (e?: AnyEvent) => void,
  (e?: AnyEvent) => void,
  (e?: AnyEvent) => void
] => {
  const [value, setValue] = useState(initialValue);
  const setToTrue = useCallback(
    (e?: AnyEvent) => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
      setValue(true);
    },
    [setValue, stopPropagation]
  );
  const setToFalse = useCallback(
    (e?: AnyEvent) => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
      setValue(false);
    },
    [setValue, stopPropagation]
  );
  const toggle = useCallback(
    (e?: AnyEvent) => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
      setValue((value) => !value);
    },
    [setValue, stopPropagation]
  );
  return [value, setToTrue, setToFalse, toggle];
};

export default useBoolean;
